import React, { useEffect } from "react";

interface PagePreviewProps {
  htmlContent: string;
  cssContent: string;
  width?: string | number;
  height?: string | number;
  audio?: string;
  audioEffect?: string;
}

const PagePreview: React.FC<PagePreviewProps> = ({
  htmlContent,
  cssContent,
  width = "400px",
  height = "800px",
  audio,
  audioEffect,
}) => {
  useEffect(() => {
    const newWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height}`
    );
    if (newWindow) {
      const audioButtonHtml = audio
        ? `<button id="play-audio-btn">Play Audio</button>`
        : "";
      const audioEffectButtonHtml = audioEffect
        ? `<button id="play-audio-effect-btn">Play Audio Effect</button>`
        : "";
      const audioElementHtml = audio
        ? `<audio id="audio-element" src="${audio}" style="display:none"></audio>`
        : "";
      const audioEffectElementHtml = audioEffect
        ? `<audio id="audio-effect-element" src="${audioEffect}" style="display:none"></audio>`
        : "";

      newWindow.document.write(`
        <html>
          <head>
            <style>
              ${cssContent}
              /* Стили для кнопок */
              #button-container {
                position: fixed;
                bottom: 10px;
                left: 10px;
                z-index: 1000;
              }
              #button-container button {
                margin-right: 8px;
                padding: 6px 12px;
                border: none;
                border-radius: 4px;
                background-color: #007bff;
                color: #fff;
                cursor: pointer;
                transition: background-color 0.3s;
              }
              #button-container button:hover {
                background-color: #0056b3;
              }
            </style>
          </head>
          <body>
            <div id="content">
              ${htmlContent}
            </div>
            <div id="button-container">
              ${audioButtonHtml}
              ${audioEffectButtonHtml}
            </div>
            ${audioElementHtml}
            ${audioEffectElementHtml}
            <script>
              (function() {
                var playAudioBtn = document.getElementById("play-audio-btn");
                if (playAudioBtn) {
                  playAudioBtn.addEventListener("click", function() {
                    var audioEl = document.getElementById("audio-element");
                    if (audioEl) {
                      audioEl.play();
                    }
                  });
                }
                var playAudioEffectBtn = document.getElementById("play-audio-effect-btn");
                if (playAudioEffectBtn) {
                  playAudioEffectBtn.addEventListener("click", function() {
                    var audioEffectEl = document.getElementById("audio-effect-element");
                    if (audioEffectEl) {
                      audioEffectEl.play();
                    }
                  });
                }
              })();
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    }
  }, [htmlContent, cssContent, width, height, audio, audioEffect]);

  return null;
};

export default PagePreview;
