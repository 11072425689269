import { FC, useRef, useEffect, useState, useCallback } from "react";
import grapesjs, { Editor } from "grapesjs";
import GjsEditor from "@grapesjs/react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getSelectedBookPagesSelector } from "../../store/selectors/books";
import { BookPage, PageToEdit } from "../../types/books";
import {
  savePageToServer,
  uploadBookPageImageToServer,
  updatePageToServer,
  uploadBookPageVideoToServer,
  uploadBookPageAudioToServer,
} from "../../store/thunks/books";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import Loader from "../Loader/Loader";
import "./PageConstructor.module.css";

interface PageConstructorProps {
  pageToEdit: PageToEdit;
  pageMode?: "vertical" | "horizontal";
  onHide: () => void;
}

const horizontalDevice = [
  {
    name: "Phone Horizontal",
    width: "776.67px",
    height: "360px",
  },
  {
    name: "Tablet Horizontal",
    width: "748px",
    height: "500.67px",
  },
];

const verticalDevice = [
  {
    name: "Phone Vertical",
    width: "360px",
    height: "776.67px",
  },
  {
    name: "Tablet Vertical",
    width: "500.67px",
    height: "748px",
  },
];

const languages = [
  { code: "en", name: "English" },
  { code: "zh", name: "Chinese" },
  { code: "es", name: "Spanish" },
  { code: "ar", name: "Arabic" },
  { code: "fr", name: "French" },
  { code: "ru", name: "Russian" },
  { code: "de", name: "German" },
  { code: "ja", name: "Japanese" },
  { code: "pt", name: "Portuguese" },
  { code: "hi", name: "Hindi" },
];

const PageConstructor: FC<PageConstructorProps> = ({
  pageToEdit,
  pageMode = "horizontal",
  onHide,
}) => {
  const dispatch = useAppDispatch();
  const selectedBookPages = useAppSelector(getSelectedBookPagesSelector);
  const selectedBook = useAppSelector((state) => state.books.selectedBook);

  const mainImgRef = useRef<string | null>(null);
  const videoRef = useRef<string | null>(null);
  const audioRef = useRef<string | null>(null);
  const audioEffectRef = useRef<string | null>(null);

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [ilugFontName, setIlugFontName] = useState("Arial, sans-serif");
  const [ilugPhoneFontSize, setIlugPhoneFontSize] = useState(16);
  const [ilugTabletFontSize, setIlugTabletFontSize] = useState(16);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);

  const getPageToEdit = useCallback(() => {
    if (!pageToEdit.isEdit) return null;
    return selectedBookPages.find(
      (page) => page.pageNumber === pageToEdit.pageNumber
    );
  }, [pageToEdit.isEdit, pageToEdit.pageNumber, selectedBookPages]);

  useEffect(() => {
    const cssUrl = "https://unpkg.com/grapesjs/dist/css/grapes.min.css";
    let cssLink = document.querySelector(
      `link[href="${cssUrl}"]`
    ) as HTMLLinkElement;
    if (cssLink && cssLink.sheet) {
      setIsCSSLoaded(true);
    } else if (cssLink) {
      cssLink.addEventListener("load", () => setIsCSSLoaded(true));
      cssLink.addEventListener("error", () => setIsCSSLoaded(false));
    } else {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = cssUrl;
      link.onload = () => setIsCSSLoaded(true);
      link.onerror = () => setIsCSSLoaded(false);
      document.head.appendChild(link);
    }
  }, []);

  useEffect(() => {
    if (isEditorReady && isCSSLoaded) {
      setIsLoading(false);
    }
  }, [isEditorReady, isCSSLoaded]);

  useEffect(() => {
    const page = getPageToEdit();
    if (page) {
      mainImgRef.current = page.pageContent.images.mainImg;
      if (page.pageContent.video) {
        videoRef.current = page.pageContent.video;
      }
      if (page.pageContent.audio) {
        audioRef.current = page.pageContent.audio;
      }
      if (page.pageContent.audioEffect) {
        audioEffectRef.current = page.pageContent.audioEffect;
      }
    }
  }, [selectedBookPages, pageToEdit, getPageToEdit]);

  const onEditor = (editor: Editor) => {
    editor.Keymaps.remove("delete");
    editor.Panels.removeButton("options", "fullscreen");
    editor.Panels.removeButton("options", "preview");
    editor.Panels.removeButton("options", "export-template");
    editor.Panels.removeButton("views", "open-blocks");

    const page = getPageToEdit();

    if (page) {
      editor.setComponents(page.pageContent.html);
      editor.setStyle(page.pageContent.css);

      const mainImgElement = editor.getWrapper()?.find("#main-img")[0];
      const scrollImgElement = editor.getWrapper()?.find("#scroll-img")[0];
      const ilugElement = editor.getWrapper()?.find("#ilug")[0];

      if (mainImgElement && page.pageContent.images.mainImg) {
        mainImgElement.addStyle({
          "background-image": `url(${page.pageContent.images.mainImg})`,
        });
        mainImgElement.set({
          removable: false,
          draggable: false,
          copyable: false,
        });
      }

      if (scrollImgElement) {
        scrollImgElement.addStyle({
          "background-image": `url(${selectedBook?.scrollImg})`,
        });
        scrollImgElement.set({
          removable: false,
          draggable: false,
          copyable: false,
        });
      }

      if (ilugElement && page.pageContent.font) {
        ilugElement.addStyle({
          "font-family": page.pageContent.font.name,
          "font-size": `${page.pageContent.font.phoneSize}px`,
        });
        setIlugFontName(page.pageContent.font.name);
        setIlugPhoneFontSize(page.pageContent.font.phoneSize);
        setIlugTabletFontSize(page.pageContent.font.tabletSize);

        editor.setDevice("Tablet");
        ilugElement.addStyle({
          "font-size": `${page.pageContent.font.tabletSize}px`,
        });
      }
    } else {
      const mainImgComp = editor.addComponents(`
        <div id="main-img" style="height:100%; width:100%; position: absolute; background-size:cover; background-position:center;"></div>
      `)[0];
      mainImgComp.set({ removable: false, draggable: false, copyable: false });

      const scrollImgComp = editor.addComponents(`
        <div id="scroll-img" style="
          position: fixed; 
          bottom: 0; 
          left: 0; 
          right: 0; 
          width: 100%; 
          height: 200px; 
          background-color: rgba(240, 240, 240, 0.1); 
          overflow: auto; 
          background-image: url(${selectedBook?.scrollImg}); 
          background-repeat: no-repeat; 
          background-size: 100% auto; 
          background-position: top; 
          box-sizing: border-box; 
          border-top: 1px solid #ccc; 
          display: flex; 
          justify-content: center; 
          align-items: center;" 
          class="gjs-f-butterfly">
          <div style="
            text-align: center; 
            padding: 10px; 
            width: 100%; 
            overflow: auto;">
            <p id="ilug" style="
              font-size: 16px; 
              font-family: 'Playtime With Hot Toddies'; 
              color: #000; 
              margin: auto;">
              Enter your text here
            </p>
          </div>
        </div>
      `)[0];

      scrollImgComp.set({
        removable: false,
        draggable: false,
        copyable: false,
      });
    }

    editor.StyleManager.addSector("typography", {
      name: "Typography",
      open: true,
      buildProps: ["font-family", "font-size", "font-weight", "color"],
      properties: [
        {
          property: "font-family",
          name: "Font",
          defaults: "Arial, sans-serif",
          list: [
            {
              name: "Playtime With Hot Toddies",
              value: "Playtime With Hot Toddies",
              id: "1",
            },
            { name: "Arial", value: "Arial, sans-serif", id: "2" },
            { name: "Georgia", value: "Georgia, serif", id: "3" },
          ],
        },
      ],
    });

    editor.StyleManager.addSector("text-styles", {
      name: "Text Styles",
      open: true,
      buildProps: ["font-size", "color", "width", "height", "font-family"],
      properties: [
        { property: "font-size", name: "Font Size" },
        { property: "color", name: "Text Color" },
        { property: "width", name: "Width" },
        { property: "height", name: "Height" },
        {
          property: "font-family",
          name: "Font",
          value: "Playtime With Hot Toddies",
          id: "1",
        },
      ],
    });

    editor.Commands.add("set-bg-image", {
      run: async (editor, sender) => {
        sender.set("active", 0);
        const mainImgElement = editor.getWrapper()?.find("#main-img")[0];
        if (!mainImgElement) {
          alert("The main image block (#main-img) is not found.");
          return;
        }
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.onchange = async (event: Event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              const resultAction = await dispatch(
                uploadBookPageImageToServer({ file, title: "title-of-image" })
              );
              const imageUrl = unwrapResult(resultAction);
              mainImgElement.addStyle({
                "background-image": `url("${process.env.REACT_APP_GC_URI}/${imageUrl}")`,
                height: "100%",
                width: "100%",
                position: "absolute",
                "background-size": "cover",
                "background-position": "center",
              });
              mainImgRef.current = `${process.env.REACT_APP_GC_URI}/${imageUrl}`;

              if (videoRef.current) {
                const videoComponent = editor.getWrapper()?.find("video")[0];
                if (videoComponent) {
                  videoComponent.remove();
                }
                videoRef.current = "";
              }
            } catch (error) {
              alert("Failed to upload the image. Please try again.");
            }
          }
        };
        fileInput.click();
      },
    });

    editor.Commands.add("set-video", {
      run: async (editor, sender) => {
        sender.set("active", 0);
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "video/*";
        fileInput.onchange = async (event: Event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              const resultAction = await dispatch(
                uploadBookPageVideoToServer({ file, title: "title-of-video" })
              );
              const videoUrl = unwrapResult(resultAction);
              const fullVideoUrl = `${process.env.REACT_APP_GC_URI}/${videoUrl}`;

              if (mainImgRef.current) {
                const mainImgElement = editor
                  .getWrapper()
                  ?.find("#main-img")[0];
                if (mainImgElement) {
                  mainImgElement.removeStyle("background-image");
                }
                mainImgRef.current = "";
              }
              videoRef.current = fullVideoUrl;

              const videoComponent = editor.addComponents(`
                <video autoplay loop muted style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
                  <source src="${fullVideoUrl}" type="${file.type}" />
                  Your browser doesn't support the video tag.
                </video>
              `)[0];
              videoComponent.set({
                removable: false,
                draggable: false,
                copyable: false,
              });
            } catch (error) {
              alert("Failed to upload the video. Please try again.");
            }
          }
        };
        fileInput.click();
      },
    });

    editor.Commands.add("set-audio", {
      run: async (editor, sender) => {
        sender.set("active", 0);
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "audio/*";
        fileInput.onchange = async (event: Event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              const resultAction = await dispatch(
                uploadBookPageAudioToServer({ file, title: "audio-title" })
              );
              const audioUrl = unwrapResult(resultAction);
              audioRef.current = `${process.env.REACT_APP_GC_URI}/${audioUrl}`;
              alert("Audio uploaded successfully!");
              updateAudioIndicators();
            } catch (error) {
              alert("Failed to upload audio. Please try again.");
            }
          }
        };
        fileInput.click();
      },
    });

    editor.Commands.add("set-audio-effect", {
      run: async (editor, sender) => {
        sender.set("active", 0);
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "audio/*";
        fileInput.onchange = async (event: Event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              const resultAction = await dispatch(
                uploadBookPageAudioToServer({
                  file,
                  title: "audio-effect-title",
                })
              );
              const audioEffectUrl = unwrapResult(resultAction);
              audioEffectRef.current = `${process.env.REACT_APP_GC_URI}/${audioEffectUrl}`;
              alert("Audio effect uploaded successfully!");
              updateAudioIndicators();
            } catch (error) {
              alert("Failed to upload audio effect. Please try again.");
            }
          }
        };
        fileInput.click();
      },
    });

    editor.Commands.add("remove-audio", {
      run: (editor, sender) => {
        sender.set("active", 0);
        audioRef.current = "";
        alert("Audio removed successfully!");
        updateAudioIndicators();
      },
    });

    editor.Commands.add("remove-audio-effect", {
      run: (editor, sender) => {
        sender.set("active", 0);
        audioEffectRef.current = "";
        alert("Audio effect removed successfully!");
        updateAudioIndicators();
      },
    });

    const updateAudioIndicators = () => {
      const btnAudio = editor.Panels.getButton("options", "set-audio");
      const btnAudioEffect = editor.Panels.getButton(
        "options",
        "set-audio-effect"
      );
      if (btnAudio) {
        const currentAttributes = btnAudio.get("attributes") || {};
        btnAudio.set("attributes", {
          ...currentAttributes,
          style: `color: ${audioRef.current ? "green" : "red"};`,
        });
      }
      if (btnAudioEffect) {
        const currentAttributes = btnAudioEffect.get("attributes") || {};
        btnAudioEffect.set("attributes", {
          ...currentAttributes,
          style: `color: ${audioEffectRef.current ? "green" : "red"};`,
        });
      }
    };

    editor.Commands.add("edit-bottom-block", {
      run: (editor, sender) => {
        sender.set("active", 0);
        const selected = editor.getSelected();
        if (!selected) {
          alert(
            "Select a block attached to the bottom of the screen to change its height."
          );
          return;
        }
        const el = selected.getEl();
        if (!el?.classList.contains("gjs-f-butterfly")) {
          alert(
            "The selected element is not a block attached to the bottom of the screen."
          );
          return;
        }
        const newHeight = prompt(
          "Enter the new block height (e.g., 300px):",
          //@ts-ignore
          selected.getStyle()["height"]
        );
        if (newHeight) {
          selected.addStyle({ height: newHeight });
        }
      },
    });

    editor.Commands.add("select-language", {
      run: (editor, sender) => {
        sender.set("active", 0);
        const languageOptions = languages
          .map((lang) => `${lang.code} - ${lang.name}`)
          .join("\n");
        const languageCode = prompt(
          `Enter the language code:\n${languageOptions}`,
          selectedLanguage
        );
        if (
          languageCode &&
          languages.some((lang) => lang.code === languageCode)
        ) {
          setSelectedLanguage(languageCode);
          alert(`Language set to ${languageCode}`);
        } else {
          alert("Invalid language code.");
        }
      },
    });

    editor.Commands.add("upload-to-server", {
      run: async (editor) => {
        const wrapperEl = editor.getWrapper()?.getEl();
        const videoElement = wrapperEl
          ? wrapperEl.querySelector("video")
          : null;
        if (!mainImgRef.current && !videoElement) {
          alert("A background image or video must be set before uploading.");
          return;
        }
        if (!mainImgRef.current && videoElement) {
          const sourceElement = videoElement.querySelector("source");
          if (sourceElement && sourceElement.getAttribute("src")) {
            mainImgRef.current = sourceElement.getAttribute("src");
          }
        }
        if (mainImgRef.current && videoRef.current) {
          const mainImgElement = editor.getWrapper()?.find("#main-img")[0];
          if (mainImgElement) {
            mainImgElement.removeStyle("background-image");
          }
          mainImgRef.current = "";
        }

        const html = editor.getHtml();
        let css = editor.getCss();
        const textBlock = editor.getWrapper()?.find("p")[0];
        const text = textBlock ? textBlock.view?.el.innerText : "";

        let pageNumber = pageToEdit.pageNumber;
        if (!pageToEdit.isEdit) {
          const pageNumberString = prompt("Enter the page number to upload:");
          if (pageNumberString === null) {
            alert("Saving was canceled.");
            return;
          }
          pageNumber = pageNumberString ? parseInt(pageNumberString, 10) : NaN;
        }
        if (pageNumber === null || isNaN(pageNumber)) {
          alert("Invalid page number.");
          return;
        }
        if (!pageToEdit.isEdit) {
          const pagesWithSameNumber =
            selectedBookPages.filter(
              (page) => page.pageNumber === pageNumber
            ) || [];
          if (pagesWithSameNumber.length > 0) {
            alert(
              `Page number ${pageNumber} already exists. Please choose a different number.`
            );
            return;
          }
        }
        const scrollBlock = editor.getWrapper()?.find("#scroll-img")[0];
        const scrollHeight = scrollBlock
          ? scrollBlock.view?.el.clientHeight
          : 0;
        if (!scrollHeight) {
          alert("Scroll height is not set.");
          return;
        }
        const ilugElement = editor.getWrapper()?.find("#ilug")[0];
        let ilugFont = undefined;
        if (ilugElement) {
          const ilugStyles = ilugElement.getStyle();
          const fontSizeValue = Array.isArray(ilugStyles["font-size"])
            ? ilugStyles["font-size"][0]
            : ilugStyles["font-size"];
          const phoneSize = ilugStyles["font-size"]
            ? parseInt(fontSizeValue, 10)
            : ilugPhoneFontSize;
          const tabletSize = ilugStyles["font-size"]
            ? parseInt(fontSizeValue, 10)
            : ilugTabletFontSize;
          ilugFont = {
            name: Array.isArray(ilugStyles["font-family"])
              ? ilugStyles["font-family"][0]
              : ilugStyles["font-family"] || ilugFontName,
            phoneSize: isNaN(phoneSize) ? ilugPhoneFontSize : phoneSize,
            tabletSize: isNaN(tabletSize) ? ilugTabletFontSize : tabletSize,
          };
        }

        const pageData: BookPage = {
          pageNumber,
          pageContent: {
            html,
            css: css || "",
            images: {
              mainImg: mainImgRef.current || "",
            },
            video: videoRef.current || "",
            audio: audioRef.current || "",
            audioEffect: audioEffectRef.current || "",
            text: text || "",
            scrollHeight: { phone: scrollHeight, tablet: scrollHeight },
            font: ilugFont
              ? {
                  name: ilugFont.name,
                  phoneSize: ilugFont.phoneSize,
                  tabletSize: ilugFont.tabletSize,
                }
              : {
                  name: "Arial, sans-serif",
                  phoneSize: 16,
                  tabletSize: 16,
                },
            languageCode: selectedLanguage,
          },
          width:
            pageMode === "horizontal"
              ? horizontalDevice[0].width
              : verticalDevice[0].width,
          height:
            pageMode === "horizontal"
              ? horizontalDevice[0].height
              : verticalDevice[0].height,
          _id: page ? page._id : undefined,
        };

        try {
          const bookId = selectedBook?._id;
          if (!bookId) {
            alert("Book ID is missing.");
            return;
          }
          if (pageToEdit.isEdit) {
            await dispatch(updatePageToServer({ bookId, pageData })).unwrap();
          } else {
            await dispatch(savePageToServer({ bookId, pageData })).unwrap();
          }
          onHide();
        } catch (error) {
          if (isAxiosError(error) && error.response) {
            alert(
              `Failed to upload the page: ${
                error.response.data.message || error.message
              }`
            );
          } else {
            alert("Failed to upload the page: " + error);
          }
        }
      },
    });

    editor.Panels.addButton("options", {
      id: "select-language",
      className: "fa fa-language",
      command: "select-language",
      attributes: { title: "Select Language" },
    });
    editor.Panels.addButton("options", {
      id: "cancel-editing",
      className: "fa fa-times",
      command: () => {
        if (window.confirm("Are you sure you want to cancel editing?")) {
          onHide();
        }
      },
      attributes: { title: "Cancel Editing" },
    });
    editor.Panels.addButton("options", {
      id: "upload-to-server",
      className: "fa fa-save",
      command: "upload-to-server",
      attributes: { title: "Upload page to server" },
    });
    editor.Panels.addButton("options", {
      id: "set-bg-image",
      className: "fa fa-image",
      command: "set-bg-image",
      attributes: { title: "Upload background image" },
    });
    editor.Panels.addButton("options", {
      id: "set-video",
      className: "fa fa-video-camera",
      command: "set-video",
      attributes: { title: "Upload video" },
    });
    editor.Panels.addButton("options", {
      id: "set-audio",
      className: "fa fa-music",
      command: "set-audio",
      attributes: { title: "Upload Audio" },
    });
    editor.Panels.addButton("options", {
      id: "remove-audio",
      className: "fa fa-trash",
      command: "remove-audio",
      attributes: { title: "Remove Audio" },
    });
    editor.Panels.addButton("options", {
      id: "set-audio-effect",
      className: "fa fa-volume-up",
      command: "set-audio-effect",
      attributes: { title: "Upload Audio Effect" },
    });
    editor.Panels.addButton("options", {
      id: "remove-audio-effect",
      className: "fa fa-trash",
      command: "remove-audio-effect",
      attributes: { title: "Remove Audio Effect" },
    });
    editor.Panels.addButton("options", {
      id: "edit-bottom-block",
      className: "fa fa-arrows-v",
      command: "edit-bottom-block",
      attributes: {
        title:
          "Change the height of the block attached to the bottom of the screen",
      },
    });

    updateAudioIndicators();

    editor.on("component:update", (component) => {
      const styles = component.getStyle();
      if (component.getId() === "ilug") {
        if (styles["font-family"]) {
          const fontFamilyValue = Array.isArray(styles["font-family"])
            ? styles["font-family"][0]
            : styles["font-family"];
          setIlugFontName(fontFamilyValue);
        }
        if (styles["font-size"]) {
          const fontSizeValue = Array.isArray(styles["font-size"])
            ? styles["font-size"][0]
            : styles["font-size"];
          const size = parseInt(fontSizeValue, 10);
          const device = editor.getDevice();
          if (device.includes("Phone")) {
            setIlugPhoneFontSize(isNaN(size) ? ilugPhoneFontSize : size);
          } else if (device.includes("Tablet")) {
            setIlugTabletFontSize(isNaN(size) ? ilugTabletFontSize : size);
          }
        }
      }
    });

    setIsEditorReady(true);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <GjsEditor
        grapesjs={grapesjs}
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        options={{
          height: "100vh",
          storageManager: false,
          fromElement: true,
          showDevices: true,
          devicePreviewMode: true,
          showToolbar: false,
          deviceManager: {
            devices:
              pageMode === "horizontal" ? horizontalDevice : verticalDevice,
          },
        }}
        onEditor={onEditor}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};

export default PageConstructor;
