import React, { useState, useRef, useEffect } from "react";
import styles from "./AudioUploader.module.css";
import MiniButton from "../buttons/MiniButton/MiniiButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface AudioUploaderProps {
  onAudioChange?: (file: File | null) => void;
  onAudioDelete?: (isDeleted: boolean) => void;
  initialAudioUrl?: string;
  editMode?: boolean;
}

const AudioUploader: React.FC<AudioUploaderProps> = ({
  onAudioChange,
  onAudioDelete,
  initialAudioUrl = "",
  editMode = false,
}) => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioUrl, setAudioUrl] = useState<string>(initialAudioUrl);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    setAudioUrl(initialAudioUrl);
    setAudioFile(null);
  }, [initialAudioUrl]);

  useEffect(() => {
    return () => {
      if (audioFile && audioUrl.startsWith("blob:")) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioFile, audioUrl]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (audioFile && audioUrl.startsWith("blob:")) {
        URL.revokeObjectURL(audioUrl);
      }
      const url = URL.createObjectURL(file);
      setAudioFile(file);
      setAudioUrl(url);
      if (onAudioChange) {
        onAudioChange(file);
      }
      if (onAudioDelete) {
        onAudioDelete(false);
      }
    }
  };

  const handleDelete = () => {
    if (!editMode) return;
    if (audioFile && audioUrl.startsWith("blob:")) {
      URL.revokeObjectURL(audioUrl);
    }
    setAudioFile(null);
    setAudioUrl("");
    if (onAudioDelete) {
      onAudioDelete(true);
    }
    if (onAudioChange) {
      onAudioChange(null);
    }
  };

  if (!editMode) {
    return audioUrl ? (
      <div className={styles.container}>
        <audio controls src={audioUrl} className={styles.audioPlayer} />
      </div>
    ) : null;
  }

  return (
    <div className={styles.container}>
      {audioUrl ? (
        <div className={styles.audioContainer}>
          <audio
            ref={audioRef}
            src={audioUrl}
            controls
            className={styles.audioPlayer}
          />
          <div className={styles.buttonGroup}>
            <MiniButton secondary onClick={handleDelete}>
              <DeleteIcon />
            </MiniButton>
          </div>
        </div>
      ) : (
        <label className={styles.uploadButton}>
          Upload Audio
          <input
            type="file"
            accept="audio/*"
            onChange={handleFileUpload}
            className={styles.hiddenInput}
          />
        </label>
      )}
    </div>
  );
};

export default AudioUploader;
