import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { logout, setAccessToken, setRefreshToken } from "../slices/auth";
import store from "../store";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add the Authorization header to every request if accessToken exists
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: any): Promise<never> => Promise.reject(error)
);

let isRefreshing = false;

interface FailedRequest {
  resolve: (value?: unknown) => void;
  reject: (error: any) => void;
}

let failedQueue: FailedRequest[] = [];

const processQueue = (error: any, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // console.log("✅ Response successful:", response);
    return response;
  },
  (error: AxiosError): Promise<any> => {
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };
    // console.log("🚀 ~ originalRequest:", originalRequest);

    // Check for 401 error and no retry yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          //@ts-ignore
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const state = store.getState();
          const refreshToken = state.auth.refreshToken;

          if (refreshToken) {
            // console.log(
            //   "🔑 Refresh token found. Making request to refresh tokens..."
            // );

            // Make request to refresh tokens
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/authentication/refresh-tokens`,
              { refreshToken }
            );

            const { accessToken, refreshToken: newRefreshToken } =
              response.data;

            if (accessToken && newRefreshToken) {
              // console.log(
              //   "✅ Tokens refreshed successfully. Saving new tokens..."
              // );

              // Update tokens in Redux store
              store.dispatch(setAccessToken(accessToken));
              store.dispatch(setRefreshToken(newRefreshToken));

              // Process the queued requests
              processQueue(null, accessToken);

              // Set new access token on original request
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${accessToken}`;

              // Retry original request with new token
              // console.log("🔄 Retrying original request with new token...");
              resolve(axiosInstance(originalRequest));
            } else {
              // console.error("🚨 New accessToken or refreshToken not received");
              store.dispatch(logout());
              processQueue("No new tokens", null);
              reject(error);
            }
          } else {
            // console.error("🚨 Refresh token missing. Logging out...");
            store.dispatch(logout());
            processQueue("No refresh token", null);
            reject(error);
          }
        } catch (refreshError: any) {
          processQueue(refreshError, null);

          // Use isAxiosError to check
          if (axios.isAxiosError(refreshError)) {
            // Check the status of the token refresh error
            if (
              refreshError.response?.status === 401 ||
              refreshError.response?.status === 403
            ) {
              // console.error("🚨 Invalid refreshToken. Logging out...");
              store.dispatch(logout());

              // Set _retry to false to avoid further retries
              originalRequest._retry = false;
            } else {
              // console.error("🚨 Error refreshing tokens:", refreshError);
            }
          } else {
            // If the error is not an AxiosError type
            // console.error("🚨 Unknown error:", refreshError);
          }

          reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      });
    }

    // console.error(
    //   "❌ Error not related to 401 status or retry attempt:",
    //   error
    // );
    return Promise.reject(error);
  }
);

export default axiosInstance;
