import { isAxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { BookPage, IBook } from "../../types/books";
import { RootState } from "../store";

import axiosInstance from "../instance/axiosInstance";
import { setResult, setError, setLoading } from "../slices/app";
import { mapBookData } from "../../helpers/createBookData";
import { UploadBookBuilderPayload, UploadBookPayload } from "../types";

const booksResult = {
  success: {
    title: "Success",
    content: `Book successfully uploaded`,
  },
  edit: {
    title: "Edit",
    content: `Book successfully edited`,
  },
  delete: {
    title: "Delete",
    content: `Book successfully deleted`,
  },
};

export const fetchBooks = createAsyncThunk<
  IBook[],
  undefined,
  { state: RootState }
>("books/fetchBooks", async (_, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axiosInstance.get<IBook[]>("/books");
    return data.map(mapBookData);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const uploadBook = createAsyncThunk<
  IBook,
  UploadBookBuilderPayload,
  { state: RootState }
>(
  "books/uploadBook",
  async ({ data, files }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("author", data.author);
      formData.append("minAge", data.minAge.toString());
      formData.append("price", data.price.toString());
      formData.append("pageMode", data.pageMode?.toString() || "horizontal");

      if (data.description) {
        formData.append("description", data.description);
      }

      if (data.backCoverURI) {
        formData.append("backCoverURI", data.backCoverURI);
      }

      if (data.externalStoreURl) {
        formData.append("externalStoreURl", data.externalStoreURl);
      }

      if (data.externalStoreCoverURl) {
        formData.append("externalStoreCoverURl", data.externalStoreCoverURl);
      }

      if (data.partNumber) {
        formData.append("partNumber", data.partNumber);
      }

      if (files.cover) {
        formData.append("cover", files.cover);
      }
      if (files.scrollImg) {
        formData.append("scrollImg", files.scrollImg);
      }

      if (files.book) {
        formData.append("book", files.book);
      }

      if (files.externalStoreCover) {
        formData.append("externalStoreCover", files.externalStoreCover);
      }

      if (data.categories && data.categories.length > 0) {
        formData.append("categories", data.categories.join(","));
      }

      const response = await axiosInstance.post<IBook>("/books", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(setResult(booksResult.success));

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editBook = createAsyncThunk<
  IBook,
  UploadBookPayload,
  { state: RootState }
>("books/editBook", async (updatedBook, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));

    const formData = new FormData();

    formData.append("title", updatedBook.data.title);
    formData.append("author", updatedBook.data.author);
    formData.append("minAge", updatedBook.data.minAge.toString());
    formData.append("price", updatedBook.data.price.toString());

    if (updatedBook.data.description) {
      formData.append("description", updatedBook.data.description);
    }

    if (updatedBook.data.backCoverURI) {
      formData.append("backCoverURI", updatedBook.data.backCoverURI);
    }

    if (updatedBook.data.externalStoreURl) {
      formData.append("externalStoreURl", updatedBook.data.externalStoreURl);
    }

    if (updatedBook.data.externalStoreCoverURl) {
      formData.append(
        "externalStoreCoverURl",
        updatedBook.data.externalStoreCoverURl
      );
    }

    if (updatedBook.data.partNumber) {
      formData.append("partNumber", updatedBook.data.partNumber);
    }

    if (updatedBook.files.cover) {
      formData.append("cover", updatedBook.files.cover);
    } else {
      // console.log("Cover is missing");
    }

    if (updatedBook.files.scrollImg) {
      formData.append("scrollImg", updatedBook.files.scrollImg);
    }

    if (updatedBook.files.externalStoreCover) {
      formData.append(
        "externalStoreCover",
        updatedBook.files.externalStoreCover
      );
    }

    if (updatedBook.files.audioBackground) {
      formData.append("audioBackground", updatedBook.files.audioBackground);
    }

    if (updatedBook.filesToDelete.audioBackground) {
      formData.append("audioBackgroundURI", "delete and unset");
    }

    if (updatedBook.data.categories && updatedBook.data.categories.length > 0) {
      formData.append("categories", updatedBook.data.categories.join(","));
    }

    const response = await axiosInstance.patch<IBook>(
      `/books/${updatedBook.data._id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch(setResult(booksResult.edit));
    dispatch(fetchBooks());

    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const deleteBook = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("books/deleteBook", async (bookId, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance.delete(`/books/${bookId}`);

    dispatch(setResult(booksResult.delete));
    dispatch(fetchBooks());
    return bookId;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const uploadBookPageImageToServer = createAsyncThunk<
  string,
  { file: File; title: string },
  { state: RootState }
>(
  "books/uploadBookPageImageToServer",
  async ({ file, title }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);
      const response = await axiosInstance.post(
        "/books/builder/upload-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to upload the image to the server.");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const uploadBookPageVideoToServer = createAsyncThunk<
  string,
  { file: File; title: string },
  { state: RootState }
>(
  "books/uploadBookPageVideoToServer",
  async ({ file, title }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);

      const response = await axiosInstance.post(
        "/books/builder/upload-video",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to upload the audio to the server.");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const uploadBookPageAudioToServer = createAsyncThunk<
  string,
  { file: File; title: string },
  { state: RootState }
>(
  "books/uploadBookPageAudioToServer",
  async ({ file, title }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);

      const response = await axiosInstance.post(
        "/books/builder/upload-audio",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to upload the audio to the server.");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const uploadBuilderBook = createAsyncThunk<
  IBook,
  UploadBookBuilderPayload,
  { state: RootState }
>(
  "books/uploadBuilderBook",
  async ({ data, files }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("minAge", data.minAge.toString());
      formData.append("price", data.price.toString());
      formData.append("author", data.author);
      formData.append("pageMode", data.pageMode || "vertical");

      if (files.cover) {
        formData.append("cover", files.cover);
      }
      if (files.scrollImg) {
        formData.append("scrollImg", files.scrollImg);
      }
      if (files.audioBackground) {
        formData.append("audioBackground", files.audioBackground);
      }
      if (data.description) {
        formData.append("description", data.description);
      }
      if (data.categories) {
        formData.append("categories", data.categories.join(","));
      }

      const response = await axiosInstance.post<IBook>("/books", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(setResult(booksResult.success));

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const savePageToServer = createAsyncThunk(
  "books/savePageToServer",
  async (
    { bookId, pageData }: { bookId: string; pageData: BookPage },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setLoading(true));
    try {
      const payload = {
        bookId,
        pageNumber: pageData.pageNumber,
        pageContent: {
          html: pageData.pageContent.html,
          css: pageData.pageContent.css || "",
          images: {
            mainImg: pageData.pageContent.images.mainImg,
          },
          video: pageData.pageContent.video,
          audio: pageData.pageContent.audio,
          audioEffect: pageData.pageContent.audioEffect,
          font: {
            name: pageData.pageContent.font?.name,
            phoneSize: pageData.pageContent.font?.phoneSize,
            tabletSize: pageData.pageContent.font?.tabletSize,
          },
          text: pageData.pageContent.text || "",
          scrollHeight: pageData.pageContent.scrollHeight,
          languageCode: pageData.pageContent.languageCode,
        },
        width: pageData.width,
        height: pageData.height,
      };

      const response = await axiosInstance.post(
        "/books/builder/pages",
        payload
      );

      dispatch(setResult(booksResult.success));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || "Failed to save page"
        );
      }
      return rejectWithValue("Failed to save page");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updatePageToServer = createAsyncThunk(
  "books/updatePageToServer",
  async (
    { bookId, pageData }: { bookId: string; pageData: BookPage },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setLoading(true));
    try {
      const payload = {
        bookId,
        pageNumber: pageData.pageNumber,
        pageContent: {
          html: pageData.pageContent.html,
          css: pageData.pageContent.css || "",
          images: {
            mainImg: pageData.pageContent?.video
              ? ""
              : pageData.pageContent.images?.mainImg,
          },
          video: pageData.pageContent.video,
          audio: pageData.pageContent.audio,
          audioEffect: pageData.pageContent.audioEffect,
          text: pageData.pageContent.text || "",
          font: {
            name: pageData.pageContent.font?.name,
            phoneSize: pageData.pageContent.font?.phoneSize,
            tabletSize: pageData.pageContent.font?.tabletSize,
          },
          scrollHeight: pageData.pageContent.scrollHeight,
          languageCode: pageData.pageContent.languageCode,
        },
        width: pageData.width,
        height: pageData.height,
      };

      const response = await axiosInstance.patch(
        `/books/builder/pages/${pageData._id}`,
        payload
      );

      dispatch(setResult(booksResult.edit));
      return response.data;
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data || "Failed to update page");
      }
      return rejectWithValue("Failed to update page");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchBookPages = createAsyncThunk<BookPage[], string>(
  "books/fetchBookPages",
  async (bookId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));

    try {
      const response = await axiosInstance.get(
        `/books/builder/pages/${bookId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || "Failed to fetch pages"
        );
      }
      return rejectWithValue("Failed to fetch pages");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deletePage = createAsyncThunk<string, string>(
  "books/deletePage",
  async (pageId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(`/books/builder/pages/${pageId}`);
      dispatch(setResult(booksResult.delete));
      return pageId;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || "Failed to delete page"
        );
      }
      return rejectWithValue("Failed to delete page");
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const publishBook = createAsyncThunk<
  IBook,
  string,
  { state: RootState }
>("books/publishBook", async (bookId, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await axiosInstance.patch(
      `/books/builder/publish/${bookId}`
    );

    dispatch(fetchBooks());
    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(
        (error as any).response?.data || "Failed to publish book"
      );
    }
    return rejectWithValue("Failed to publish book");
  } finally {
    dispatch(setLoading(false));
  }
});
