import React from "react";
import styles from "./PageThumbnail.module.css";
import AudioPlayerButton from "../buttons/AudioPlayerButton/AudioPlayerButton";

interface PageThumbnailProps {
  pageNumber: number;
  imageUrl?: string;
  videoUrl?: string;
  audio?: string;
  audioEffect?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}

const PageThumbnail: React.FC<PageThumbnailProps> = ({
  pageNumber,
  imageUrl,
  videoUrl,
  audio,
  audioEffect,
  width = "200px",
  height = "300px",
  onClick,
}) => {
  const fullMediaUrl = (url: string) => {
    return /^https?:\/\//i.test(url)
      ? url
      : `${process.env.REACT_APP_GC_URI}/${url}`;
  };

  return (
    <div
      onClick={onClick}
      style={{ width, height }}
      className={styles.thumbnailContainer}
      title="Нажмите для предпросмотра страницы"
    >
      {imageUrl ? (
        <img
          src={fullMediaUrl(imageUrl)}
          alt={`Страница ${pageNumber}`}
          className={styles.thumbnailImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : videoUrl ? (
        <video
          src={fullMediaUrl(videoUrl)}
          className={styles.thumbnailImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          controls
        />
      ) : null}
      <div className={styles.pageNumber}>{pageNumber}</div>
      <div className={styles.audioButtons}>
        {audio && (
          <AudioPlayerButton
            src={fullMediaUrl(audio)}
            playLabel="▶️ Play Audio"
            pauseLabel="⏸ Pause Audio"
            className={styles.playButton}
          />
        )}
        {audioEffect && (
          <AudioPlayerButton
            src={fullMediaUrl(audioEffect)}
            playLabel="▶️ Play Audio Effect"
            pauseLabel="⏸ Pause Audio Effect"
            className={styles.playButton}
          />
        )}
      </div>
    </div>
  );
};

export default PageThumbnail;
