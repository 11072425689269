import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import InputField from "../../components/InputField/InputField";
import MainButton from "../../components/buttons/MainButton/MainButton";
import ResultModal from "../../components/modals/ResultModal/ResultModal";
import CategorySelector from "../../components/CategorySelector/CategorySelector";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import AudioUploader from "../../components/AudioUploader/AudioUploader";
import PageThumbnail from "../../components/PageThumbnail/PageThumbnail";
import PagePreview from "../../components/PagePreview/PagePreview"; // Импорт PagePreview

import { RootState } from "../../store/store";
import { IBook } from "../../types/books";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { deleteBook, editBook, publishBook } from "../../store/thunks/books";
import { categoriesSelector } from "../../store/selectors/categories";
import { bookByIdSelector } from "../../store/selectors/books";
import { UploadBookPayload } from "../../store/types";

import styles from "./BookPage.module.css";

interface PreviewData {
  htmlContent: string;
  cssContent: string;
  width: string;
  height: string;
  audio?: string;
  audioEffect?: string;
}

const BookPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const book = useAppSelector((state: RootState) =>
    bookByIdSelector(state, id)
  );

  const categories = useAppSelector(categoriesSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IBook>({
    defaultValues: {
      title: book?.title || "",
      author: book?.author || "",
      description: book?.description || "",
      price: book?.price || 0,
      minAge: book?.minAge || 0,
      categories: book?.categories || [],
      coverURI: book?.coverURI || "",
      backCoverURI: book?.backCoverURI || "",
      externalStoreURl: book?.externalStoreURl || "",
      externalStoreCoverURl: book?.externalStoreCoverURl || "",
      partNumber: book?.partNumber || "",
    },
  });

  const [editMode, setEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [coverImage, setCoverImage] = useState<File | null>(null);
  const uploadCoverImage = async (file: File | null) => {
    setCoverImage(file);
  };

  const [scrollImage, setScrollImage] = useState<File | null>(null);
  const uploadScrollImg = async (file: File | null) => {
    setScrollImage(file);
  };

  const [externalCoverOption, setExternalCoverOption] = useState<
    "url" | "file" | ""
  >("");
  const [externalCoverFile, setExternalCoverFile] = useState<File | null>(null);
  const uploadExternalCoverFile = async (file: File | null) => {
    setExternalCoverFile(file);
  };

  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [isAudioDeleted, setIsAudioDeleted] = useState<boolean>(false);

  const [previewData, setPreviewData] = useState<PreviewData | null>(null);

  if (!id) {
    return <div>Invalid Book ID</div>;
  }

  if (!book) {
    return (
      <div className={styles.bookDetails}>
        <p className={styles.bookNotFound}>Book not found</p>
      </div>
    );
  }

  const onSubmit = async (data: IBook) => {
    const updatedBook: UploadBookPayload = {
      data: {
        ...data,
        _id: book._id,
        externalStoreCoverURl:
          externalCoverOption === "url" ? data.externalStoreCoverURl : "",
      },
      files: {
        cover: coverImage,
        scrollImg: scrollImage,
        externalStoreCover:
          externalCoverOption === "file" ? externalCoverFile : null,
        audioBackground: audioFile,
      },
      filesToDelete: {
        audioBackground: isAudioDeleted,
      },
    };

    try {
      await dispatch(editBook(updatedBook)).unwrap();
      setEditMode(false);
    } catch (error) {
      // console.error("Error editing book:", error);
    }
  };

  const handleDelete = () => {
    if (book._id) {
      dispatch(deleteBook(book._id)).then(() => {
        navigate("/dashboard/books");
      });
    }
  };

  const handlePageClick = (
    htmlContent: string,
    cssContent: string,
    width: string | number = "400px",
    height: string | number = "800px",
    audio?: string,
    audioEffect?: string
  ) => {
    setPreviewData({
      htmlContent,
      cssContent,
      width: typeof width === "number" ? `${width}px` : width,
      height: typeof height === "number" ? `${height}px` : height,
      audio,
      audioEffect,
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (previewData) {
      const timer = setTimeout(() => {
        setPreviewData(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [previewData]);

  const handlePublish = async () => {
    if (book._id) {
      dispatch(publishBook(book._id));
    }
  };

  return (
    <div className={styles.bookDetails}>
      <h1>Book Details</h1>
      <div className={styles.bookImages}>
        <div className={styles.bookImage}>
          <h4>Cover Image</h4>
          <ImageUploader
            image={coverImage}
            imageUrl={typeof book.coverURI === "string" ? book.coverURI : null}
            onImageChange={uploadCoverImage}
            editMode={editMode}
            inputId="coverImage"
          />
        </div>
        <div className={styles.bookImage}>
          <h4>Scroll Image</h4>
          <ImageUploader
            image={scrollImage}
            imageUrl={
              typeof book.scrollImg === "string" ? book.scrollImg : null
            }
            onImageChange={uploadScrollImg}
            editMode={editMode}
            inputId="scrollImage"
          />
        </div>
      </div>

      {!editMode && book.audioBackgroundURI && (
        <div className={styles.audioDisplay}>
          <h4>Audio Background</h4>
          <AudioUploader
            editMode={false}
            initialAudioUrl={book.audioBackgroundURI}
          />
        </div>
      )}

      {editMode ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.inputsContainer}
        >
          <div className={styles.inputGroup}>
            <label className={styles.audio_bgnd}>
              Audio Background (optional)
            </label>
            <AudioUploader
              editMode={true}
              initialAudioUrl={book.audioBackgroundURI || ""}
              onAudioChange={setAudioFile}
              onAudioDelete={setIsAudioDeleted}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Title"
                  {...field}
                  error={errors.title ? errors.title.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Author</label>
            <Controller
              name="author"
              control={control}
              rules={{ required: "Author is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Author"
                  {...field}
                  error={errors.author ? errors.author.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Description</label>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Description"
                  {...field}
                  textarea
                  error={errors.description ? errors.description.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Minimal age</label>
            <Controller
              name="minAge"
              control={control}
              rules={{ required: "Minimal age is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Minimal age"
                  {...field}
                  error={errors.minAge?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Price</label>
            <Controller
              name="price"
              control={control}
              rules={{ required: "Price is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Price"
                  {...field}
                  error={errors.price?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Categories</label>
            <Controller
              name="categories"
              control={control}
              rules={{ required: "At least one category is required" }}
              render={({ field, fieldState }) => (
                <CategorySelector
                  field={field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Back Cover URI</label>
            <Controller
              name="backCoverURI"
              control={control}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Back Cover URI - optional"
                  {...field}
                  error={errors.backCoverURI?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>External Store URL</label>
            <Controller
              name="externalStoreURl"
              control={control}
              render={({ field }) => (
                <InputField
                  type="url"
                  placeholder="External Store URL - optional"
                  {...field}
                  error={errors.externalStoreURl?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>External Store Cover</label>
            <div className={styles.toggleGroup}>
              <label>
                <input
                  type="radio"
                  value="url"
                  checked={externalCoverOption === "url"}
                  onChange={() => {
                    setExternalCoverOption("url");
                    setValue("externalStoreCoverURl", "");
                    setExternalCoverFile(null);
                  }}
                />
                <span>URL</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="file"
                  checked={externalCoverOption === "file"}
                  onChange={() => {
                    setExternalCoverOption("file");
                    setValue("externalStoreCoverURl", "");
                  }}
                />
                <span>Upload File</span>
              </label>
            </div>
            {externalCoverOption === "url" && (
              <Controller
                name="externalStoreCoverURl"
                control={control}
                rules={{
                  pattern: {
                    value: /^(https?:\/\/)?([\w\d\-]+\.)+\w{2,}(\/.+)*\/?$/i,
                    message: "Invalid URL format",
                  },
                }}
                render={({ field }) => (
                  <InputField
                    type="url"
                    placeholder="External Store Cover URL - optional"
                    {...field}
                    error={errors.externalStoreCoverURl?.message}
                  />
                )}
              />
            )}

            {externalCoverOption === "file" && (
              <div className={styles.fileUploadContainer}>
                <ImageUploader
                  image={externalCoverFile}
                  imageUrl={null}
                  onImageChange={uploadExternalCoverFile}
                  editMode={editMode}
                  inputId="externalStoreCoverFile"
                />
                {errors.externalStoreCoverURl && (
                  <span className={styles.error}>
                    {errors.externalStoreCoverURl.message}
                  </span>
                )}
              </div>
            )}
          </div>

          <div className={styles.inputGroup}>
            <label>Part Number</label>
            <Controller
              name="partNumber"
              control={control}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Part Number - optional"
                  {...field}
                  error={errors.partNumber?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <MainButton label="Save" type="submit" />
        </form>
      ) : (
        <div className={styles.mainContainer}>
          <h1 style={{ marginBottom: "30px" }}>{book.title}</h1>
          {book.author && (
            <h3 style={{ marginBottom: "30px" }}>by: {book.author}</h3>
          )}
          {book.description && <p>Description: {book.description}</p>}
          <p>Minimal age: {book.minAge}</p>
          <p>Price: {book.price}</p>
          {book.backCoverURI && (
            <p>
              Back Cover URI:{" "}
              <a
                href={book.backCoverURI}
                target="_blank"
                rel="noopener noreferrer"
              >
                {book.backCoverURI}
              </a>
            </p>
          )}
          {book.externalStoreURl && (
            <p>
              External Store URL:{" "}
              <a
                href={book.externalStoreURl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {book.externalStoreURl}
              </a>
            </p>
          )}
          {book.externalStoreCoverURl && (
            <p>
              External Store Cover URL:{" "}
              <a
                href={book.externalStoreCoverURl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {book.externalStoreCoverURl}
              </a>
            </p>
          )}
          {book.partNumber && <p>Part Number: {book.partNumber}</p>}
          {book.pdfUrl && !book.pdfUrl.includes("undefined") && (
            <p>
              <a href={book.pdfUrl} target="_blank" rel="noopener noreferrer">
                Download Book
              </a>
            </p>
          )}
          <p>
            Categories:{" "}
            {book.categories
              .map(
                (id) => categories.find((category) => category._id === id)?.name
              )
              .join(", ")}
          </p>
          <div className={styles.buttonsContainer}>
            <MainButton label="Edit" onClick={() => setEditMode(true)} />
            <MainButton
              label={book.isPublished ? "Unpublish" : "Publish"}
              warning={book.isPublished}
              onClick={() => handlePublish()}
            />
            <MainButton
              label="Delete"
              warning
              onClick={() => setModalOpen(true)}
            />
          </div>

          <div className={styles.pageTypeContainer}>
            <div className={styles.pageTypeContent}>
              {book?.pages?.map((page, index) => (
                <div key={index} className={styles.pageItem}>
                  <PageThumbnail
                    videoUrl={page.pageContent.video}
                    pageNumber={page.pageNumber}
                    imageUrl={page.pageContent.images.mainImg}
                    audio={page.pageContent.audio}
                    audioEffect={page.pageContent.audioEffect}
                    width="150px"
                    height="250px"
                    onClick={() =>
                      handlePageClick(
                        page.pageContent.html,
                        page.pageContent.css,
                        page.width,
                        page.height,
                        page.pageContent.audio,
                        page.pageContent.audioEffect
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <ResultModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Delete Book"
        confirmLabel="Delete"
        onConfirm={handleDelete}
      >
        <p>Do you really want to delete this book?</p>
      </ResultModal>
      {previewData && (
        <PagePreview
          htmlContent={previewData.htmlContent}
          cssContent={previewData.cssContent}
          width={previewData.width}
          height={previewData.height}
          audio={previewData.audio}
          audioEffect={previewData.audioEffect}
        />
      )}
    </div>
  );
};

export default BookPage;
