import React, { useState, useRef } from "react";

interface AudioPlayerButtonProps {
  src: string;
  playLabel?: string;
  pauseLabel?: string;
  className?: string;
}

const AudioPlayerButton: React.FC<AudioPlayerButtonProps> = ({
  src,
  playLabel = "▶️ Play",
  pauseLabel = "⏸ Pause",
  className = "",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!src) return;

    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    } else {
      audioRef.current = new Audio(src);
      audioRef.current.play();
      setIsPlaying(true);
      audioRef.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }
  };

  return (
    <button className={`${className}`} onClick={handleToggle}>
      {isPlaying ? pauseLabel : playLabel}
    </button>
  );
};

export default AudioPlayerButton;
